var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "is-add-roles",
      visible: _vm.isAddRoles,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: { hidden: _vm.resetForm },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "b-overlay",
              { attrs: { show: _vm.tableLoading, rounded: "sm" } },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
                  },
                  [
                    _c("h4", { staticClass: "mb-0" }, [
                      _vm._v(" Create Roles ")
                    ]),
                    _c("feather-icon", {
                      staticClass: "ml-1 cursor-pointer",
                      attrs: { icon: "XIcon", size: "16" },
                      on: {
                        click: function($event) {
                          _vm.cancel()
                          hide()
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "p-2" },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { md: "12" } }, [
                          _c("p", [
                            _vm._v(
                              "This can be a role a healthcare professional is required to carry out or competency they need to have"
                            )
                          ])
                        ]),
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Role name",
                                  "label-for": "login-email"
                                }
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Break Minutes",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "login-email",
                                                name: "login-email",
                                                max: "20",
                                                type: "text",
                                                placeholder: ""
                                              },
                                              model: {
                                                value: _vm.form.roleName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "roleName",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.roleName"
                                              }
                                            }),
                                            errors[0]
                                              ? _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "The Role name field is required"
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", [_vm._v("Which professions")]),
                                _c("validation-provider", {
                                  attrs: { name: "Role", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("v-select", {
                                              attrs: {
                                                label: "text",
                                                placeholder: "Select Role",
                                                multiple: "",
                                                reduce: function(roleList) {
                                                  return roleList.value
                                                },
                                                options: _vm.roleList
                                              },
                                              model: {
                                                value: _vm.form.professions,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "professions",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.professions"
                                              }
                                            }),
                                            errors[0]
                                              ? _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "The Role field is required"
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "mt-2", attrs: { md: "12" } },
                          [_c("p", [_vm._v("Role type")])]
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "6" } },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                staticClass: "float-left",
                                attrs: { value: "clinical" },
                                model: {
                                  value: _vm.form.type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "type", $$v)
                                  },
                                  expression: "form.type"
                                }
                              },
                              [_vm._v(" Clinical ")]
                            ),
                            _c(
                              "b-form-checkbox",
                              {
                                staticClass: "float-right",
                                attrs: { value: "non-clinical" },
                                model: {
                                  value: _vm.form.type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "type", $$v)
                                  },
                                  expression: "form.type"
                                }
                              },
                              [_vm._v(" Non-clinical ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex mt-3" },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(255, 255, 255, 0.15)",
                                expression: "'rgba(255, 255, 255, 0.15)'",
                                modifiers: { "400": true }
                              }
                            ],
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              disabled:
                                _vm.form.professions == "" ||
                                _vm.form.roleName == "" ||
                                _vm.form.type == ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.addRole()
                              }
                            }
                          },
                          [_vm._v(" Add ")]
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(186, 191, 199, 0.15)",
                                expression: "'rgba(186, 191, 199, 0.15)'",
                                modifiers: { "400": true }
                              }
                            ],
                            staticClass: "shadow text-primary ml-1",
                            attrs: { type: "button", variant: "outline-white" },
                            on: {
                              click: function($event) {
                                _vm.cancel()
                                hide()
                              }
                            }
                          },
                          [_vm._v(" Cancel ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }