<template>
    <b-card class="mp-0">

       <!-- Tabs -->

      <b-tabs v-model="tabIndex" >
        <b-tab title="Set Rates" lazy>
          <div class="alert-body"><Role /> </div>
        </b-tab>
        <b-tab title="Add Roles" lazy>
          <div class="alert-body"><AddRole /> </div>
        </b-tab>
        <b-tab title="Rota Management" lazy>
          <div class="alert-body"> <Rota /></div>
        </b-tab>
        <b-tab title="Shift Templates" lazy>
          <div class="alert-body"><ShiftTemplates /> </div>
        </b-tab>
        <b-tab title="User Management" lazy>
          <div class="alert-body"><User /> </div>
        </b-tab>
        <b-tab title="Admin access invitations" lazy>
          <div class="alert-body"><users-invitations/> </div>
        </b-tab>
        <b-tab title="Connected PCN" lazy>
          <div class="alert-body"><ConnectedPCN /> </div>
        </b-tab>


      </b-tabs>
   </b-card>

</template>

<script>
import Role from './view/role'
import AddRole from './view/addRole.vue'
import Rota from './view/rota'
import User from './view/user'
import UsersInvitations from './view/users-invitations'
import ShiftTemplates from './view/shiftTemplates'
import ConnectedPCN from './view/connecetdPCN'


import { BCard, BTabs , BTab, BButton, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Home',
  components: {
    Role,
    Rota,
    User,
    BCard,
    BButton,
    BTabs,
    BTab,
    UsersInvitations,
    ShiftTemplates,
    AddRole,
    ConnectedPCN
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 100,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      tabIndex:this.$route.query.active_tab,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'name',
          label: 'NAME',
          sortable: true
        },
        {
          key: 'email',
          label: 'EMAIL',
        },
        {
          key: 'telephone',
          label:'telephone',
        },
        {
          key: 'account_created',
          label: 'Date account created',
          sortable: true
        },
        {
          key: 'redate',
          label: 'Last Login date',
        },
        {
          key: 'action',
          label: '',
        },

      ],
      items: [


      ]
    }
  },

}
</script>
