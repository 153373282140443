var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "mp-0" },
    [
      _c(
        "b-tabs",
        {
          model: {
            value: _vm.tabIndex,
            callback: function($$v) {
              _vm.tabIndex = $$v
            },
            expression: "tabIndex"
          }
        },
        [
          _c("b-tab", { attrs: { title: "Set Rates", lazy: "" } }, [
            _c("div", { staticClass: "alert-body" }, [_c("Role")], 1)
          ]),
          _c("b-tab", { attrs: { title: "Add Roles", lazy: "" } }, [
            _c("div", { staticClass: "alert-body" }, [_c("AddRole")], 1)
          ]),
          _c("b-tab", { attrs: { title: "Rota Management", lazy: "" } }, [
            _c("div", { staticClass: "alert-body" }, [_c("Rota")], 1)
          ]),
          _c("b-tab", { attrs: { title: "Shift Templates", lazy: "" } }, [
            _c("div", { staticClass: "alert-body" }, [_c("ShiftTemplates")], 1)
          ]),
          _c("b-tab", { attrs: { title: "User Management", lazy: "" } }, [
            _c("div", { staticClass: "alert-body" }, [_c("User")], 1)
          ]),
          _c(
            "b-tab",
            { attrs: { title: "Admin access invitations", lazy: "" } },
            [
              _c(
                "div",
                { staticClass: "alert-body" },
                [_c("users-invitations")],
                1
              )
            ]
          ),
          _c("b-tab", { attrs: { title: "Connected PCN", lazy: "" } }, [
            _c("div", { staticClass: "alert-body" }, [_c("ConnectedPCN")], 1)
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }