<template>
  <div>
    <main class="">
      <div>
        <div class="card">
          <div class="row">
            <div class="col-12 col-lg-5 col-xl-3 border-right">
              <div class="d-none d-md-block">
                <!-- <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="filters['search']"
                        id="icons-search"
                        placeholder="Search"
                      />
                    </b-input-group>
                  </div>
                </div> -->
              </div>
              <b-overlay :show="tableLoading" rounded="sm" class="mt-1">
                <vue-perfect-scrollbar
                  v-if="pcn.length"
                  class="scrollable-container media-list scroll-area"
                  style="height: 640px; solid: #e5e5e5"
                >
                  <b-table
                    ref="connectedPcn"
                    :fields="fieldPCNList"
                    :items="getConnectedPcn"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    thead-class="d-none"
                    hover
                    primary-key="id"
                    responsive
                    @row-clicked="getSalariedStaffs"
                  >
                    <template #cell(pcn)="data">
                      <div class="d-flex">
                        <div class="ml-1" style="padding-top: 2px">
                          <div>
                            <span
                              class="ml-1 font-weight-bolder text-dark mb-0"
                              >{{ data.item.pcn_name }}</span
                            >
                          </div>
                          <div>
                            <span
                              style="
                                font-family: Mulish;
                                font-size: 12px;
                                color: #949494;
                              "
                              class="ml-1"
                              >{{ data.item.count }} Users</span
                            >
                          </div>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </vue-perfect-scrollbar>
                <div class="" v-else>
                  <b-overlay :show="usersLoading" rounded="sm" class="mt-1">
                    <div style="height: 100px"></div>
                    <div class="text-center">
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M25 0C38.8072 0 50 11.1928 50 25C50 38.8072 38.8072 50 25 50C11.1928 50 0 38.8072 0 25C0 11.1928 11.1928 0 25 0Z"
                          fill="#CCE6F4"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M34.8938 12.5996C35.6281 12.5996 36.2235 13.1951 36.2235 13.9294C36.2235 14.6637 35.6281 15.2592 34.8938 15.2592C34.1592 15.2592 33.564 14.6637 33.564 13.9294C33.564 13.1951 34.1592 12.5996 34.8938 12.5996Z"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M38.6171 8.34375C39.6453 8.34375 40.4788 9.17726 40.4788 10.2055C40.4788 11.2336 39.6453 12.0672 38.6171 12.0672C37.5889 12.0672 36.7554 11.2336 36.7554 10.2055C36.7554 9.17726 37.5889 8.34375 38.6171 8.34375Z"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M33.0319 7.8125C33.3258 7.8125 33.5638 8.05053 33.5638 8.34441C33.5638 8.6383 33.3258 8.87633 33.0319 8.87633C32.738 8.87633 32.5 8.6383 32.5 8.34441C32.5 8.05053 32.738 7.8125 33.0319 7.8125Z"
                          fill="white"
                        />
                        <path
                          d="M34.701 20.1504C32.7913 20.1504 31.2363 21.7053 31.2363 23.615C31.2363 25.5247 32.7913 27.0797 34.701 27.0797C36.6107 27.0797 38.1656 25.5247 38.1656 23.615C38.1656 21.7053 36.6107 20.1504 34.701 20.1504ZM34.701 25.6938C33.5548 25.6938 32.6222 24.7612 32.6222 23.615C32.6222 22.4689 33.5548 21.5362 34.701 21.5362C35.8471 21.5362 36.7798 22.4689 36.7798 23.615C36.7798 24.7612 35.847 25.6938 34.701 25.6938Z"
                          fill="black"
                        />
                        <path
                          d="M15.3975 20.1504C13.4864 20.1504 11.9329 21.7053 11.9329 23.615C11.9329 25.5247 13.4878 27.0797 15.3975 27.0797C17.3072 27.0797 18.8622 25.5247 18.8622 23.615C18.8622 21.7053 17.3086 20.1504 15.3975 20.1504ZM15.3975 25.6938C14.2514 25.6938 13.3187 24.7612 13.3187 23.615C13.3187 22.4689 14.2514 21.5362 15.3975 21.5362C16.5436 21.5362 17.4763 22.4689 17.4763 23.615C17.4763 24.7612 16.5436 25.6938 15.3975 25.6938Z"
                          fill="black"
                        />
                        <path
                          d="M34.7007 28.4648C33.5601 28.4648 32.4431 28.7767 31.4702 29.3657C31.1445 29.5652 31.0392 29.9907 31.2374 30.3178C31.4383 30.6449 31.8624 30.7488 32.1895 30.5506C32.9447 30.0918 33.8123 29.8507 34.7007 29.8507C37.3754 29.8507 39.5512 32.0265 39.5512 34.7013C39.5512 35.0838 39.8617 35.3942 40.2442 35.3942C40.6267 35.3942 40.9372 35.0838 40.9372 34.7013C40.9371 31.2629 38.139 28.4648 34.7007 28.4648Z"
                          fill="black"
                        />
                        <path
                          d="M18.528 29.3643C17.5565 28.7767 16.4395 28.4648 15.2989 28.4648C11.8606 28.4648 9.0625 31.2629 9.0625 34.7013C9.0625 35.0838 9.37296 35.3942 9.75546 35.3942C10.138 35.3942 10.4484 35.0838 10.4484 34.7013C10.4483 32.0265 12.6242 29.8507 15.2989 29.8507C16.1873 29.8507 17.0548 30.0918 17.8087 30.5492C18.1344 30.7474 18.5613 30.6434 18.7608 30.3163C18.9604 29.9893 18.855 29.5638 18.528 29.3643Z"
                          fill="black"
                        />
                        <path
                          d="M24.9999 25.6934C20.0329 25.6934 15.9917 29.7345 15.9917 34.7015C15.9917 35.084 16.3022 35.3945 16.6847 35.3945C17.0672 35.3945 17.3776 35.084 17.3776 34.7015C17.3776 30.4982 20.7965 27.0793 24.9999 27.0793C29.2032 27.0793 32.6221 30.4982 32.6221 34.7015C32.6221 35.084 32.9326 35.3945 33.3151 35.3945C33.6976 35.3945 34.008 35.084 34.008 34.7015C34.008 29.7345 29.9668 25.6934 24.9999 25.6934Z"
                          fill="#1B9AAA"
                        />
                        <path
                          d="M25 14.6055C22.3253 14.6055 20.1494 16.7813 20.1494 19.456C20.1494 22.1308 22.3252 24.3066 25 24.3066C27.6747 24.3066 29.8505 22.1307 29.8505 19.456C29.8505 16.7813 27.6747 14.6055 25 14.6055ZM25 22.9207C23.0903 22.9207 21.5353 21.3657 21.5353 19.456C21.5353 17.5463 23.0903 15.9914 25 15.9914C26.9097 15.9914 28.4646 17.5463 28.4646 19.456C28.4646 21.3657 26.9097 22.9207 25 22.9207Z"
                          fill="#1B9AAA"
                        />
                        <path
                          d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                          fill="#1B9AAA"
                          stroke="#1B9AAA"
                          stroke-width="0.5"
                        />
                        <mask
                          id="mask0_1872_13634"
                          style="mask-type: alpha"
                          maskUnits="userSpaceOnUse"
                          x="14"
                          y="11"
                          width="6"
                          height="5"
                        >
                          <path
                            d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_1872_13634)">
                          <path
                            d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                            fill="#1B9AAA"
                          />
                          <mask
                            id="mask1_1872_13634"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="14"
                            y="11"
                            width="6"
                            height="5"
                          >
                            <path
                              d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                              fill="white"
                            />
                          </mask>
                          <g mask="url(#mask1_1872_13634)"></g>
                        </g>
                      </svg>

                      <h5 class="mt-2">No PCNS</h5>
                      <div style="height: 100px"></div>
                    </div>
                  </b-overlay>
                </div>
              </b-overlay>
            </div>

            <div class="col-12 col-xl-9">
              <div class="" v-if="userList.length === 0">
                <b-overlay :show="usersLoading" rounded="sm" class="mt-1">
                  <div style="height: 100px"></div>
                  <div class="text-center">
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M25 0C38.8072 0 50 11.1928 50 25C50 38.8072 38.8072 50 25 50C11.1928 50 0 38.8072 0 25C0 11.1928 11.1928 0 25 0Z"
                        fill="#CCE6F4"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M34.8938 12.5996C35.6281 12.5996 36.2235 13.1951 36.2235 13.9294C36.2235 14.6637 35.6281 15.2592 34.8938 15.2592C34.1592 15.2592 33.564 14.6637 33.564 13.9294C33.564 13.1951 34.1592 12.5996 34.8938 12.5996Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M38.6171 8.34375C39.6453 8.34375 40.4788 9.17726 40.4788 10.2055C40.4788 11.2336 39.6453 12.0672 38.6171 12.0672C37.5889 12.0672 36.7554 11.2336 36.7554 10.2055C36.7554 9.17726 37.5889 8.34375 38.6171 8.34375Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M33.0319 7.8125C33.3258 7.8125 33.5638 8.05053 33.5638 8.34441C33.5638 8.6383 33.3258 8.87633 33.0319 8.87633C32.738 8.87633 32.5 8.6383 32.5 8.34441C32.5 8.05053 32.738 7.8125 33.0319 7.8125Z"
                        fill="white"
                      />
                      <path
                        d="M34.701 20.1504C32.7913 20.1504 31.2363 21.7053 31.2363 23.615C31.2363 25.5247 32.7913 27.0797 34.701 27.0797C36.6107 27.0797 38.1656 25.5247 38.1656 23.615C38.1656 21.7053 36.6107 20.1504 34.701 20.1504ZM34.701 25.6938C33.5548 25.6938 32.6222 24.7612 32.6222 23.615C32.6222 22.4689 33.5548 21.5362 34.701 21.5362C35.8471 21.5362 36.7798 22.4689 36.7798 23.615C36.7798 24.7612 35.847 25.6938 34.701 25.6938Z"
                        fill="black"
                      />
                      <path
                        d="M15.3975 20.1504C13.4864 20.1504 11.9329 21.7053 11.9329 23.615C11.9329 25.5247 13.4878 27.0797 15.3975 27.0797C17.3072 27.0797 18.8622 25.5247 18.8622 23.615C18.8622 21.7053 17.3086 20.1504 15.3975 20.1504ZM15.3975 25.6938C14.2514 25.6938 13.3187 24.7612 13.3187 23.615C13.3187 22.4689 14.2514 21.5362 15.3975 21.5362C16.5436 21.5362 17.4763 22.4689 17.4763 23.615C17.4763 24.7612 16.5436 25.6938 15.3975 25.6938Z"
                        fill="black"
                      />
                      <path
                        d="M34.7007 28.4648C33.5601 28.4648 32.4431 28.7767 31.4702 29.3657C31.1445 29.5652 31.0392 29.9907 31.2374 30.3178C31.4383 30.6449 31.8624 30.7488 32.1895 30.5506C32.9447 30.0918 33.8123 29.8507 34.7007 29.8507C37.3754 29.8507 39.5512 32.0265 39.5512 34.7013C39.5512 35.0838 39.8617 35.3942 40.2442 35.3942C40.6267 35.3942 40.9372 35.0838 40.9372 34.7013C40.9371 31.2629 38.139 28.4648 34.7007 28.4648Z"
                        fill="black"
                      />
                      <path
                        d="M18.528 29.3643C17.5565 28.7767 16.4395 28.4648 15.2989 28.4648C11.8606 28.4648 9.0625 31.2629 9.0625 34.7013C9.0625 35.0838 9.37296 35.3942 9.75546 35.3942C10.138 35.3942 10.4484 35.0838 10.4484 34.7013C10.4483 32.0265 12.6242 29.8507 15.2989 29.8507C16.1873 29.8507 17.0548 30.0918 17.8087 30.5492C18.1344 30.7474 18.5613 30.6434 18.7608 30.3163C18.9604 29.9893 18.855 29.5638 18.528 29.3643Z"
                        fill="black"
                      />
                      <path
                        d="M24.9999 25.6934C20.0329 25.6934 15.9917 29.7345 15.9917 34.7015C15.9917 35.084 16.3022 35.3945 16.6847 35.3945C17.0672 35.3945 17.3776 35.084 17.3776 34.7015C17.3776 30.4982 20.7965 27.0793 24.9999 27.0793C29.2032 27.0793 32.6221 30.4982 32.6221 34.7015C32.6221 35.084 32.9326 35.3945 33.3151 35.3945C33.6976 35.3945 34.008 35.084 34.008 34.7015C34.008 29.7345 29.9668 25.6934 24.9999 25.6934Z"
                        fill="#1B9AAA"
                      />
                      <path
                        d="M25 14.6055C22.3253 14.6055 20.1494 16.7813 20.1494 19.456C20.1494 22.1308 22.3252 24.3066 25 24.3066C27.6747 24.3066 29.8505 22.1307 29.8505 19.456C29.8505 16.7813 27.6747 14.6055 25 14.6055ZM25 22.9207C23.0903 22.9207 21.5353 21.3657 21.5353 19.456C21.5353 17.5463 23.0903 15.9914 25 15.9914C26.9097 15.9914 28.4646 17.5463 28.4646 19.456C28.4646 21.3657 26.9097 22.9207 25 22.9207Z"
                        fill="#1B9AAA"
                      />
                      <path
                        d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                        fill="#1B9AAA"
                        stroke="#1B9AAA"
                        stroke-width="0.5"
                      />
                      <mask
                        id="mask0_1872_13634"
                        style="mask-type: alpha"
                        maskUnits="userSpaceOnUse"
                        x="14"
                        y="11"
                        width="6"
                        height="5"
                      >
                        <path
                          d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0_1872_13634)">
                        <path
                          d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                          fill="#1B9AAA"
                        />
                        <mask
                          id="mask1_1872_13634"
                          style="mask-type: alpha"
                          maskUnits="userSpaceOnUse"
                          x="14"
                          y="11"
                          width="6"
                          height="5"
                        >
                          <path
                            d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask1_1872_13634)"></g>
                      </g>
                    </svg>
                    <h5 class="mt-2">No Users</h5>
                    <div style="height: 100px"></div>
                  </div>
                </b-overlay>
              </div>
              <div class="" v-else>
                <b-overlay :show="usersLoading" rounded="sm" class="mt-1">
                  <b-table
                    ref="connectedUsers"
                    :fields="fields"
                    :items="userList"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    hover
                    responsive
                  >
                    <template #cell(is_pcn_archive)="data">
                      <b-badge
                        variant="light-primary"
                        v-if="data.item.is_pcn_archive === 'Active'"
                        >{{ data.item.is_pcn_archive }}</b-badge
                      >
                      <b-badge variant="light-danger" v-else>{{
                        data.item.is_pcn_archive
                      }}</b-badge>
                    </template>
                    <template #cell(is_practice_archive)="data">
                      <b-badge
                        variant="light-primary"
                        v-if="data.item.is_practice_archive === 'Active'"
                        >{{ data.item.is_practice_archive }}</b-badge
                      >
                      <b-badge variant="light-danger" v-else>{{
                        data.item.is_practice_archive
                      }}</b-badge>
                    </template>
                    <template #cell(action)="data">
                      <div class="d-flex flex-row">
                        <div class="float-left">
                          <b-button
                            v-if="data.item.is_practice_archive === 'Active'"
                            v-b-tooltip.hover.top="'Archive User'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            class="btn-icon mt-1"
                            variant="danger"
                            @click="
                              removeUser(data.item)
                            "
                            size="sm"
                          >
                            <feather-icon icon="ArchiveIcon" />
                          </b-button>
                          <b-button
                            v-b-tooltip.hover.top="'Active User'"
                            variant="gradient-primary mt-1"
                            class="btn-icon"
                            @click="
                              addAgainUser(data.item)
                            "
                            size="sm"
                            v-else
                          >
                            <feather-icon icon="UserPlusIcon" />
                          </b-button>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BTable,
  BPagination,
  BBadge,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BListGroup,
  BListGroupItem,
  BAvatarGroup,
  VBTooltip,
  BOverlay,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
// import DropdownMenue from "./dropDownMenu.vue";
import MomentMixin from "@/mixins/MomentMixin";
import TimesheetAPI from "@/apis/modules/timesheet";
import ErrorMessages from "@/mixins/ErrorMessages";
import SuccessMessage from "@/mixins/SuccessMessage";
import Filter from "@/mixins/FilterMixin";
import DateDiff from "date-diff";
import PCNApi from "@/apis/modules/pcn";
import { mapGetters } from "vuex";

export default {
  components: {
    // DropdownMenue,
    BRow,
    BCol,
    BTable,
    BPagination,
    BNavItemDropdown,
    BBadge,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BCard,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    VBTooltip,
    BOverlay,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],
  data() {
    return {
      haveData: true,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      pcn: [],
      selectedUserId: 0,
      userList: [],
      selectedUser: {},
      unsubmittedIds: [],
      tableLoading: false,
      usersLoading: false,
      filters: {},
      filter: null,
      filterOn: [],
      pagination: {
        perPage: "",
        pageOptions: "",
        totalRows: "",
        from: "",
        to: "",
      },
      fieldPCNList: [
        {
          key: "pcn",
        },
      ],
      fields: [
        {
          key: "first_name",
          label: "NAME",
          sortable: true,
        },
        {
          key: "role",
          label: "role",
          sortable: false,
        },
        {
          key: "telephone",
          label: "telephone",
          sortable: false,
        },
        {
          key: "email",
          label: "email",
          sortable: false,
        },
        {
          key: "is_pcn_archive",
          label: "PCN Status",
        },
        {
          key: "is_practice_archive",
          label: "Practice Status",
        },
        {
          key: "action",
          label: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    async getConnectedPcn() {
      try {
        this.tableLoading = true;

        if (this.sortBy === "") {
          this.sortDesc = true;
        }

        const Response = await PCNApi.connectedPcns(
          this.currentPage,
          15,
          this.filterQuery,
          this.sortBy,
          this.sortDesc
        );
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          pcn_id: x.pcn.id,
          pcn_name: x.pcn.name,
          user_id: x.pcn.owner.id,
          _rowVariant: 'white'
        }));
        this.noDataTable = Response.data.data.length;
        const paginationResponse = Response.data.meta;
        this.currentPage = paginationResponse.current_page;
        this.pagination.totalRows = paginationResponse.total;
        this.pagination.perPage = paginationResponse.per_page;
        this.pagination.from = paginationResponse.from;
        this.pagination.to = paginationResponse.to;
        this.tableLoading = false;
        this.pcn = dataArray;
        return dataArray;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.tableLoading = false;
      }
    },
    async getSalariedStaffs(item) {
      try {
        this.usersLoading = true;
        item._rowVariant = 'primary'
        if (this.sortBy === "") {
          this.sortDesc = true;
        }

        const Response = await PCNApi.getPCNSalariedStaff(
          item.pcn_id,
          this.currentPage,
          15,
          this.filterQuery,
          this.sortBy,
          this.sortDesc
        );

        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          user_id: x.user.id,
          first_name: x.user.first_name + " " + x.user.last_name,
          role: x.job_role !== null ? x.job_role.name : "Practice Admin",
          location: x.practic !== null ? x.practice.name : "N/A",
          telephone: x.user.phone_number ? x.user.phone_number : "N/A",
          email: x.user.email,
          practic_id: x.practice_id,
          is_pcn_archive: x.is_pcn_archive === 0 ? "Active" : "Archived",
          is_practice_archive:
            x.is_practice_archive === 0 ? "Active" : "Archived",
          pcn_id: x.pcn_id,
        }));
        this.noDataTable = Response.data.data.length;
        const paginationResponse = Response.data.meta;
        this.currentPage = paginationResponse.current_page;
        this.pagination.totalRows = paginationResponse.total;
        this.pagination.perPage = paginationResponse.per_page;
        this.pagination.from = paginationResponse.from;
        this.pagination.to = paginationResponse.to;

        this.usersLoading = false;
        this.userList = dataArray;
        return dataArray;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.usersLoading = false;
      }
    },

    removeUser(item) {
      try {
        if (this.currentUser.id === item.user_id) {
          this.$swal({
            title: "The user is unable to archive themselves.",
            text: "",
            imageUrl: require("@/assets/images/icons/publish.png"),
            imageWidth: 80,
            imageHeight: 80,
            showCancelButton: false,
            confirmButtonText: "No, go back",
            customClass: {
              confirmButton: "btn btn-outline-danger ml-1",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          });
        } else {
          this.$swal({
            title: "Are you sure you want to Archive this user?",
            text: "",
            imageUrl: require("@/assets/images/icons/publish.png"),
            imageWidth: 80,
            imageHeight: 80,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No, go back",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.value) {
              await PCNApi.removeUser(item.user_id, item.pcn_id);
              this.getSalariedStaffs(item)
            }
          });
        }
      } catch (e) {
        this.convertAndNotifyError(e);
      }
    },

    addAgainUser(item) {
      try {
        this.$swal({
          title: "Are you sure you want to Add again this user?",
          text: "",
          imageUrl: require("@/assets/images/icons/publish.png"),
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No, go back",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            await PCNApi.addAgain(item.user_id, item.pcn_id);
            this.getSalariedStaffs(item)
          }
        });
      } catch (e) {
        this.convertAndNotifyError(e);
      }
    },

    filterQueryUpdate() {
      this.$refs.connectedPcn.refresh();
    },
  },
  mounted() {
    this.getConnectedPcn();
  },
};
</script>
<style lang="scss">
.b-avatar-img {
  padding: 0px;
}
</style>
