var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "is-edit-shift-templates-sidebar-active",
      visible: _vm.isEditSiftTemplatesSidebarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: { hidden: _vm.resetForm },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "b-overlay",
              { attrs: { show: _vm.tableLoading, rounded: "sm" } },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
                  },
                  [
                    _c("h4", { staticClass: "mb-0" }, [
                      _vm._v(" Edit shift Template ")
                    ]),
                    _c("feather-icon", {
                      staticClass: "ml-1 cursor-pointer",
                      attrs: { icon: "XIcon", size: "16" },
                      on: { click: hide }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "p-2" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label"),
                                _c("validation-provider", {
                                  attrs: { name: "Service", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "v-select",
                                              {
                                                attrs: {
                                                  options: _vm.services,
                                                  label: "name",
                                                  placeholder: "Role/ Activity",
                                                  reduce: function(services) {
                                                    return services.id
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.defaultItem.service_id,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.defaultItem,
                                                      "service_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "defaultItem.service_id"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      slot: "no-options"
                                                    },
                                                    slot: "no-options"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Please add services first "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "4" } },
                          [
                            _c("label", [_vm._v("Start Time")]),
                            _c(
                              "b-form-group",
                              [
                                _c("validation-provider", {
                                  ref: "endTime",
                                  attrs: {
                                    bails: false,
                                    name: "End Time",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "b-form-group",
                                              [
                                                _c(
                                                  "b-input-group",
                                                  {
                                                    staticClass:
                                                      "input-group-merge"
                                                  },
                                                  [
                                                    _c(
                                                      "b-input-group-prepend",
                                                      {
                                                        attrs: { "is-text": "" }
                                                      },
                                                      [
                                                        _c("feather-icon", {
                                                          attrs: {
                                                            icon: "ClockIcon"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("flat-pickr", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        config: {
                                                          enableTime: true,
                                                          noCalendar: true,
                                                          dateFormat: "H:i"
                                                        },
                                                        placeholder: "Select"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.defaultItem
                                                            .start_time,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.defaultItem,
                                                            "start_time",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "defaultItem.start_time"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            errors[0]
                                              ? _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "The End Time field is required"
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "4" } },
                          [
                            _c("label", [_vm._v("End Time")]),
                            _c("validation-provider", {
                              ref: "endTime",
                              attrs: {
                                bails: false,
                                name: "End Time",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c(
                                              "b-input-group",
                                              {
                                                staticClass: "input-group-merge"
                                              },
                                              [
                                                _c(
                                                  "b-input-group-prepend",
                                                  { attrs: { "is-text": "" } },
                                                  [
                                                    _c("feather-icon", {
                                                      attrs: {
                                                        icon: "ClockIcon"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c("flat-pickr", {
                                                  staticClass: "form-control",
                                                  attrs: {
                                                    config: {
                                                      enableTime: true,
                                                      noCalendar: true,
                                                      dateFormat: "H:i"
                                                    },
                                                    placeholder: "Select"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.defaultItem.end_time,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.defaultItem,
                                                        "end_time",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "defaultItem.end_time"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        errors[0]
                                          ? _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  "The End Time field is required"
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "4" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Break (mins)",
                                  "label-for": "login-email"
                                }
                              },
                              [
                                _c("validation-provider", {
                                  attrs: {
                                    name: "Break Minutes",
                                    rules: "numeric"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "login-email",
                                                name: "login-email",
                                                type: "number"
                                              },
                                              model: {
                                                value:
                                                  _vm.defaultItem.break_minutes,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.defaultItem,
                                                    "break_minutes",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "defaultItem.break_minutes"
                                              }
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-1" },
                      [
                        _c("label", [_vm._v("Notes (optional)")]),
                        _c("validation-provider", {
                          attrs: { name: "Notes" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-textarea", {
                                      attrs: {
                                        id: "textarea-default",
                                        placeholder: "Note",
                                        rows: "3"
                                      },
                                      model: {
                                        value: _vm.defaultItem.note,
                                        callback: function($$v) {
                                          _vm.$set(_vm.defaultItem, "note", $$v)
                                        },
                                        expression: "defaultItem.note"
                                      }
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex mt-3" },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(255, 255, 255, 0.15)",
                                expression: "'rgba(255, 255, 255, 0.15)'",
                                modifiers: { "400": true }
                              }
                            ],
                            attrs: { type: "submit", variant: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.editShiftTemplate()
                              }
                            }
                          },
                          [_vm._v(" Save ")]
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(186, 191, 199, 0.15)",
                                expression: "'rgba(186, 191, 199, 0.15)'",
                                modifiers: { "400": true }
                              }
                            ],
                            staticClass: "shadow text-primary ml-1",
                            attrs: { type: "button", variant: "outline-white" },
                            on: { click: hide }
                          },
                          [_vm._v(" Cancel ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }