var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("b-table", {
            ref: "table",
            staticClass: "mobile_table_css",
            attrs: {
              "current-page": _vm.currentPage,
              fields: _vm.fields,
              items: _vm.getUsers,
              "per-page": _vm.pagination.perPage,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              "sort-direction": _vm.sortDirection,
              hover: "",
              responsive: ""
            },
            on: {
              "update:sortBy": function($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function($event) {
                _vm.sortDesc = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "cell(first_name)",
                fn: function(data) {
                  return [
                    _c(
                      "ul",
                      { staticClass: "list-inline mb-0" },
                      [
                        _c(
                          "li",
                          { staticClass: "list-inline-item" },
                          [
                            _c("b-avatar", {
                              staticClass: "pull-up",
                              attrs: {
                                src: _vm.getUserImage(data.item.id, true)
                              }
                            })
                          ],
                          1
                        ),
                        _c("li", { staticClass: "list-inline-item" }, [
                          _vm._v(_vm._s(data.item.first_name))
                        ]),
                        _vm._l(data.item.role, function(role) {
                          return _c("b-badge", {
                            key: role.id,
                            staticClass: "ml-1",
                            staticStyle: { padding: "8px" },
                            attrs: { variant: "light-info" }
                          })
                        })
                      ],
                      2
                    )
                  ]
                }
              },
              {
                key: "cell(type_of_access)",
                fn: function(data) {
                  return _vm._l(data.item.type_of_access, function(
                    type,
                    index
                  ) {
                    return _c(
                      "span",
                      { key: index },
                      [
                        _c(
                          "b-badge",
                          {
                            staticClass: "mb-1",
                            attrs: { variant: "light-primary ml-1" }
                          },
                          [_vm._v(_vm._s(type.name))]
                        ),
                        _c("br")
                      ],
                      1
                    )
                  })
                }
              },
              {
                key: "cell(is_archive)",
                fn: function(data) {
                  return [
                    data.item.is_archive === "Active"
                      ? _c("b-badge", { attrs: { variant: "light-primary" } }, [
                          _vm._v(_vm._s(data.item.is_archive))
                        ])
                      : _c("b-badge", { attrs: { variant: "light-danger" } }, [
                          _vm._v(_vm._s(data.item.is_archive))
                        ])
                  ]
                }
              },
              {
                key: "cell(action1)",
                fn: function(data) {
                  return [
                    _c("div", { staticClass: "d-flex flex-row" }, [
                      _c(
                        "div",
                        { staticClass: "float-left" },
                        [
                          data.item.is_archive === "Active"
                            ? _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top",
                                      value: "Archive User",
                                      expression: "'Archive User'",
                                      modifiers: { hover: true, top: true }
                                    },
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(255, 255, 255, 0.15)",
                                      expression: "'rgba(255, 255, 255, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "btn-icon mt-1",
                                  attrs: { variant: "danger", size: "sm" },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeUser(data.item.id)
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "ArchiveIcon" }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top",
                                      value: "Active User",
                                      expression: "'Active User'",
                                      modifiers: { hover: true, top: true }
                                    }
                                  ],
                                  staticClass: "btn-icon",
                                  attrs: {
                                    variant: "gradient-primary mt-1",
                                    size: "sm"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.addAgainUser(data.item.id)
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "UserPlusIcon" }
                                  })
                                ],
                                1
                              )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "float-right" },
                        [
                          data.item.is_archive === "Active"
                            ? _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top",
                                      value: "Update Access",
                                      expression: "'Update Access'",
                                      modifiers: { hover: true, top: true }
                                    },
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(255, 255, 255, 0.15)",
                                      expression: "'rgba(255, 255, 255, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "btn-icon mr-2 m-1",
                                  attrs: { variant: "primary", size: "sm" },
                                  on: {
                                    click: function($event) {
                                      return _vm.assignAdminRole(data.item)
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    attrs: { icon: "EditIcon" }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("div")
        ],
        1
      ),
      _vm.pagination.totalRows === 0
        ? _c("b-col", { staticClass: "text-center", attrs: { cols: "12" } }, [
            _c("span", [_vm._v("No data for preview")])
          ])
        : _vm._e(),
      _c(
        "b-col",
        {
          staticClass:
            "\n        d-flex\n        align-items-center\n        justify-content-center justify-content-sm-start\n      ",
          attrs: { cols: "12", sm: "6" }
        },
        [
          _vm.pagination.totalRows !== 0
            ? _c("span", { staticClass: "text-muted" }, [
                _vm._v(
                  "Showing " +
                    _vm._s(_vm.pagination.from) +
                    " to " +
                    _vm._s(_vm.pagination.to) +
                    " of " +
                    _vm._s(_vm.pagination.totalRows) +
                    " entries"
                )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "b-col",
        {
          staticClass:
            "\n        d-flex\n        align-items-center\n        justify-content-center justify-content-sm-end\n      ",
          attrs: { cols: "12", sm: "6" }
        },
        [
          _c("b-pagination", {
            staticClass: "mb-0 mt-1 mt-sm-0",
            attrs: {
              "per-page": _vm.pagination.perPage,
              "total-rows": _vm.pagination.totalRows,
              "first-number": "",
              "last-number": "",
              "next-class": "next-item",
              "prev-class": "prev-item"
            },
            scopedSlots: _vm._u([
              {
                key: "prev-text",
                fn: function() {
                  return [
                    _c("feather-icon", {
                      attrs: { icon: "ChevronLeftIcon", size: "18" }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "next-text",
                fn: function() {
                  return [
                    _c("feather-icon", {
                      attrs: { icon: "ChevronRightIcon", size: "18" }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-center",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            size: "lg",
            title: "Update Role"
          },
          model: {
            value: _vm.showEditRoleModel,
            callback: function($$v) {
              _vm.showEditRoleModel = $$v
            },
            expression: "showEditRoleModel"
          }
        },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.modelShow, rounded: "sm" } },
            [
              _c(
                "b-row",
                { staticClass: "mx-1" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _c(
                        "validation-observer",
                        { ref: "InvitationSendForm" },
                        [
                          _c(
                            "b-form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                }
                              }
                            },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { clos: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {},
                                        [
                                          _c("label", [_vm._v("Name")]),
                                          _c("validation-provider", {
                                            attrs: { name: "Email", rules: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c(
                                                      "b-input-group",
                                                      {
                                                        staticClass:
                                                          "input-group-merge"
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            id: "username",
                                                            placeholder:
                                                              "Enter Name ",
                                                            readonly: "",
                                                            type: "text"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.user_name,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.user_name = $$v
                                                            },
                                                            expression:
                                                              "user_name"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-danger"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { clos: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {},
                                        [
                                          _c("label", [_vm._v("Email")]),
                                          _c("validation-provider", {
                                            attrs: { name: "Email", rules: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c(
                                                      "b-input-group",
                                                      {
                                                        staticClass:
                                                          "input-group-merge"
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            id: "name",
                                                            placeholder:
                                                              "Enter email address",
                                                            readonly: "",
                                                            type: "text"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.user_email,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.user_email = $$v
                                                            },
                                                            expression:
                                                              "user_email"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-danger"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { clos: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        {},
                                        [
                                          _c("label", [_vm._v("System Role")]),
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "System Role",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("v-select", {
                                                      attrs: {
                                                        options:
                                                          _vm.systemRoleOption,
                                                        label: "name",
                                                        multiple: "",
                                                        placeholder:
                                                          "Select system role"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.system_role_ids,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.system_role_ids = $$v
                                                        },
                                                        expression:
                                                          "system_role_ids"
                                                      }
                                                    }),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-danger"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { clos: "6" } },
                                    [
                                      _c(
                                        "b-form-group",
                                        { staticClass: "mb-2" },
                                        [
                                          _c("label", [_vm._v("Job Role")]),
                                          _c("validation-provider", {
                                            attrs: {
                                              name: "Job role",
                                              rules: "required"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  return [
                                                    _c("v-select", {
                                                      attrs: {
                                                        options:
                                                          _vm.jobRoleOption,
                                                        label: "name",
                                                        placeholder:
                                                          "Select Job role"
                                                      },
                                                      model: {
                                                        value: _vm.job_role_id,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.job_role_id = $$v
                                                        },
                                                        expression:
                                                          "job_role_id"
                                                      }
                                                    }),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "text-danger"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(errors[0])
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c("b-col", {
                                    attrs: { cols: "12", md: "12" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "float-right mb-1" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple.400",
                                          value: "rgba(255, 255, 255, 0.15)",
                                          expression:
                                            "'rgba(255, 255, 255, 0.15)'",
                                          modifiers: { "400": true }
                                        }
                                      ],
                                      staticClass: "mr-1",
                                      attrs: {
                                        type: "submit",
                                        variant: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.changeAssigneeRole()
                                        }
                                      }
                                    },
                                    [_vm._v(" Save ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "ripple",
                                          rawName: "v-ripple.400",
                                          value: "rgba(186, 191, 199, 0.15)",
                                          expression:
                                            "'rgba(186, 191, 199, 0.15)'",
                                          modifiers: { "400": true }
                                        }
                                      ],
                                      attrs: {
                                        type: "reset",
                                        variant: "outline-secondary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.resetData()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-secondary" },
                                        [_vm._v(" Reset ")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }